@import "reset";
@import "fonts";
@import "font-mixins";
@import "@angular/cdk/overlay-prebuilt.css";

:root {
  --quantum-black: #161b25;
  --quantum-white: #ffffff;
  --quantum-black-button-text: #161616;
  --extra-big: 2.15rem;
  --big: 1.75rem;
  --normal: 1rem;
}

strong {
  @include demi-bold-font();
}

div {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  @include regular-font();
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  background-color: #f9f9fa;
  color: var(--quantum-black);
}

h1 {
  @include bold-font();
  font-size: var(--big);
  color: var(--quantum-black);
}

h2 {
  @include bold-font();
  font-size: var(--big);
  color: var(--quantum-black);
}

p {
  @include regular-font();
  font-size: var(--normal);
}

.leaflet-popup-drone {
  &.leaflet-popup {
    margin-bottom: 64px;
  }

  .leaflet-popup-content-wrapper {
    background-color: rgba($color: #161616, $alpha: 0.5);
    color: #f9f9fa;
    border: 2px #f9f9fa solid;
    border-radius: 6px;

    .leaflet-popup-content {
      margin: 4px 8px;

      table {
        width: 100%;

        tr {
          td:last-child {
            padding: 0 4px;
          }
        }
      }
    }
  }

  .leaflet-popup-tip-container {
    display: none;
  }
}

.leaflet-marker-shadow {
  opacity: 0.5;
}
