@mixin bold-font {
  font-family: AvenirNext-Bold, sans-serif;
  font-size: var(--normal);
}

@mixin demi-bold-font {
  font-family: AvenirNext-DemiBold, sans-serif;
  font-size: var(--normal);
}

@mixin medium-font {
  font-family: AvenirNext-Medium, sans-serif;
  font-size: var(--normal);
}

@mixin regular-font {
  font-family: AvenirNext-Regular, sans-serif;
  font-size: var(--normal);
}
