@font-face {
  font-family: "AvenirNext-Bold";
  font-weight: bold;
  src: url(../assets/fonts/AvenirNext-Bold-01.ttf);
}

@font-face {
  font-family: "AvenirNext-DemiBold";
  font-weight: 600;
  src: url(../assets/fonts/AvenirNext-DemiBold-03.ttf);
}

@font-face {
  font-family: "AvenirNext-Medium";
  font-weight: 500;
  src: url(../assets/fonts/AvenirNext-Medium-06.ttf);
}

@font-face {
  font-family: "AvenirNext-Regular";
  font-weight: normal;
  src: url(../assets/fonts/AvenirNext-Regular-08.ttf);
}
